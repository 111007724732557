body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.barPart {
  float: left;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  border: 0px;
  vertical-align: middle;
  line-height: 40px;
  height: 40px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.paymentChangeRow {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.paymentChangeRow .amount {
  max-width: 20em;
}

.paymentChangeRow .amountSlider {
  width: 100%;
}

.paymentChangeRow .buttons {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}
